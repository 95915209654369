import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5247a319"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "hadrian-tooltip__tooltip" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["hadrian-tooltip", [_ctx.wrapperClass, { 'hadrian-tooltip-show-no-hover': _ctx.showNoHover }]])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.enabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["hadrian-tooltip__tooltip-outer", ['anchor-' + _ctx.anchor, 'vertically-' + _ctx.verticalPosition, { inset: _ctx.inset }]]),
          style: _normalizeStyle(_ctx.tooltipTextContainerStyle)
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              innerHTML: _ctx.tooltipText,
              class: _normalizeClass(`hadrian-tooltip__text ${_ctx.tooltipClass}`)
            }, null, 10, _hoisted_2)
          ])
        ], 6))
      : _createCommentVNode("", true)
  ], 2))
}