enum PermissionTypes {
  AssignProject,
  Discounts,
  PrePay,
  SalesAgencyDistributors,
  AllOrganizations,
  AccessProjects,
  CreateOrder,
  AutoReleaseOrder,
  SalesAgenciesFilter,
  DeleteProject,
  ResendOrder,
  EditAnotherUser,
  ManageAllRoles,
  ManageSalesReps,
  ManageSalesAgencyAdministrators,
  ManageDistributorAdministrators,
  ManageProjectManagers,
  CanResetPasswords,
  ManageOrganizations,
  ViewOrganizationsDiscountAndCommissionRates,
  EditOrganizationsDiscountAndCommissionRates,
  ViewOrganizationDetails,
  EditOrganizationsDetails,
  ManageOrganizationsShippingAddresses,
  CreateQuotes,
  DeleteQuotes,
  MakeBomEdits,
  AccessUsersList,
  ResetOwnPassword,
  SelectSpecialShippingMethod,
  CanOverrideMaxDiscountRate,
  CanCreateLockers,
  CanReadJdeXml,
  CanCreatePartitions,
  CanCreateDryers,
  CanCreateProjects,
  CanManageShippingUsers,
  CanSeeFailedToUploadStatus,
  CanRefreshBom,
  ResourceCenterAdmin,
  CanAccessOrganizationsSection,
  CanRequestAnOrderExpediteRequest,
  CanReviewAnOrderExpediteRequestPlanner,
  CanFilterExpediteRequestsBySalesAreaAndCustomerServiceEmployees,
  CanGetExpediteRequestEmailNotifications,
  CanGetLiveUpdatesForExpediteRequestsMadeByOthers,
  CanEditPrintedInstructionsPreference,
  CanAccessPhenolic,
  CanEditBOMMissingPartsProjects,
}

export default PermissionTypes;
