import GenderType from '@/enums/genderType';
import { BanksCalculator } from '@/helpers/banksCalculator';
import LockersHelper from '@/helpers/lockersHelper';
import NumberFormatter from '@/helpers/numberFormatter';
import StringHelper from '@/helpers/stringHelper';
import i18n from '@/i18n';
import { SuggestedPart } from '@/models/part/suggestedParts';
import RoomParts from '@/models/room/roomParts';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import { LockerBaseType, LockerRoomBases, LockerRoomBasesViewModel, ZBaseViewModel } from '../lockers/lockerRoomBases';
import LockerRoomConfiguration from '../lockers/lockerRoomConfiguration';
import { LockerRoomTops, LockerRoomTopsViewModel, LockerTopType } from '../lockers/lockerRoomTops';
import LockerRoomType from '../lockers/lockerRoomTypes';
import { LockersSeriesIds } from '../lockers/lockersSeries';
import { LockerTrimAndFillers, LockerTrimAndFillersViewModel, LockerTrimModel } from '../lockers/lockerTrimAndFillers';
import Style from '../lockers/style';
import PaginatedResponse from '../paginatedResponse';
import PatchOperation from '../patchOperation/patchOperation';
import { ProjectProductTypes, RoomBomReply } from '../project/projectApiInterfaces';
import RdDrawingNote from '../roomDesigner/rdDrawingNote';
import Room from './room';

export class RoomsResponse extends PaginatedResponse {
  readonly entities: Room[];
}

interface BaseRoomCreationRequest {
  projectId: string;
  roomName?: string;
}

interface PartitionRoomProps {
  gender: GenderType;
  roomLayoutId: string;
  unitsNumber: number;
  unitTypes: number[];
  overallWidth?: number;
  affOptionRespectingAdaStandardsEnabledForAdaAndAmbUnits: boolean;
}

export interface LockerRoomAssistant {
  betweenWallConditions: number;
  corners: number;
  exposedSides: number;
  hasSlopeTops: boolean;
  hasBases: boolean;
  exposedLeftSides: number;
  exposedRightSides: number;
  backToBack: number;
  rdaIsEnabled: boolean;
  slopeTopsAreDirty: boolean;
  basesAreDirty: boolean;
}

export interface LockerRoomExtraParts {
  id: number | null;
  benchStyleId: number;
  benchLengthId: number;
  quantity: number;
}
export interface LockerRoomProps {
  id?: number;
  lockersStyleId: number;
  roomTypeId: number;
  banks: Record<number, number>;
  standardLockerQuantity: number;
  bootRacksQuantity: number;
  bootRacksStartersQuantity: number;
  bootRacksAddersQuantity: number;
  optionalStandardLockers: boolean;
  adaLockerQuantity: number;
  standardLockQuantity: number;
  adaLockQuantity: number;
  adaShelvesQuantity: number;
  extraSidesQuantity: number;
  banksPreferenceId: number;
  roomAssistant: LockerRoomAssistant;
  trimAndFillers: LockerTrimAndFillers;
  extraParts: LockerRoomExtraParts[];
  tops: LockerRoomTops;
  bases: LockerRoomBases;
}

export interface PartitionRoomCreationRequest extends BaseRoomCreationRequest {
  productType: ProjectProductTypes.Partition;
  partitionRoom: PartitionRoomProps;
}

export interface LockerRoomCreationRequest extends BaseRoomCreationRequest {
  productType: ProjectProductTypes.Lockers;
  lockerRoomConfiguration: LockerRoomProps;
}

export interface LockerRoomUpdateRequest extends LockerRoomCreationRequest {
  roomId: string;
}

export interface DeleteRoomStyleRequest {
  roomId: string;
  lockerRoomConfigurationId: number;
}

export interface AddStyleToRoomRequest {
  projectId: string;
  roomId: string;
  lockerRoomConfiguration: LockerRoomProps;
}

export interface PartitionRoomUpdateRequest extends BaseRoomCreationRequest {
  roomId: string;
  productType: ProjectProductTypes.Partition;
  partitionRoom: PartitionRoomProps;
}

export class RoomDrawingRequest {
  operations: PatchOperation[];
  projectId: string;
}

export class RoomDuplicateRequest {
  roomId: string;
  mirror: boolean;
}

export class RoomPatchOperation extends PatchOperation {
  roomId: string;
}

export class RoomsGetParams {
  projectId: string;
  perPage?: number;
  page?: number;
}

export class RoomGetParams {
  projectId: string;
  roomId: string;
}

export class RoombBOMParts {
  entity: RoomBomReply;
}

export class SingleRoomPartsResponse {
  entity: RoomParts;
}

export class RoomPartsResponse {
  readonly addedPaint: boolean;
  readonly roomParts: RoomParts[];
  readonly autoAddedParts: SuggestedPart[];
  readonly suggestedParts: SuggestedPart[];
}

export class RoomPartsEditRequest {
  roomId: string;
  partId: string;
  quantityDelta?: number;
  finalQuantity?: number;
  customLabel?: string;
  affValue?: string;
  ceilingHeight?: string;
  doorHeight?: string;
  doorWidth?: string;
}

export class RoomCustomLengthPartRemovalRequest {
  roomId: string;
  partId: string;
}

export class RoomSuggestedPartsEditRequest {
  roomId: string;
  parts: RoomPartsEditRequest[];
}
export class RoomPartsDeleteBomEditRequest {
  roomId: string;
  roomPartId: string;
}
export class RoomPartsDeleteRequest {
  roomId: string;
  projectId: string;
}

export class RoomPriceObject {
  readonly key: string;
  readonly value: number;
}

export class DrawingSvgReply {
  drawingDataSvg: string;
}

export class NewOrderReply {
  rooms: Room[];
  notes: { [roomId: string]: RdDrawingNote[] };
}

export type Range1to6 = 1 | 2 | 3 | 4 | 5 | 6;
export type LockerBankQuantities = Record<Range1to6, number>;
export class LockerRoomAssistantModel {
  betweenWallsQuantity: number;
  cornersQuantity: number;
  totalExposedQuantity: number;
  hasSlopeTops: boolean;
  hasBases: boolean;
  leftExposedQuantity: number;
  rightExposedQuantity: number;
  backToBackQuantity: number;
  rdaIsEnabled: boolean;
  slopeTopsAreDirty: boolean;
  basesAreDirty: boolean;

  constructor() {
    this.betweenWallsQuantity = 0;
    this.cornersQuantity = 0;
    this.totalExposedQuantity = 0;
    this.hasSlopeTops = false;
    this.hasBases = false;
    this.leftExposedQuantity = 0;
    this.rightExposedQuantity = 0;
    this.backToBackQuantity = 0;
    this.rdaIsEnabled = false;
    this.slopeTopsAreDirty = false;
    this.basesAreDirty = false;
  }
}

export interface TrimAndFillerRdaProps {
  preferredStarterTrimWidth: number;
  preferredExpansionTrimWidth: number;
}

export class LockerRoomViewModel {
  static fromData(config: LockerRoomConfiguration, style: Style, roomName: string): LockerRoomViewModel {
    const viewModel = new this();
    viewModel._name = roomName;
    viewModel._style = style;
    viewModel._roomType = config.roomType;
    if (viewModel._style?.lockersSeries.id == LockersSeriesIds.BootRacks) {
      viewModel._bootRacksQuantity = StringHelper.positiveIntegerFormatter(config.bootRacksQuantity.toString());
      viewModel._bootRacksNumericalQuantity = config.bootRacksQuantity;
      viewModel._bootRacksStartersQuantity = StringHelper.positiveIntegerFormatter(
        config.bootRacksStartersQuantity.toString()
      );
      viewModel._bootRacksStartersNumericalQuantity = config.bootRacksStartersQuantity;
      viewModel._bootRacksAddersQuantity = StringHelper.positiveIntegerFormatter(
        config.bootRacksAddersQuantity.toString()
      );
      viewModel._bootRacksAddersNumericalQuantity = config.bootRacksAddersQuantity;
    } else {
      viewModel._standardLockerQuantity = StringHelper.positiveIntegerFormatter(
        config.standardLockerQuantity.toString()
      );
      viewModel._standardLockerNumericalQuantity = config.standardLockerQuantity;
      viewModel._adaLockerQuantity = StringHelper.positiveIntegerFormatter(config.adaLockerQuantity.toString());
      viewModel._adaLockerNumericalQuantity = config.adaLockerQuantity;
      viewModel._standardLocksQuantity = StringHelper.positiveIntegerFormatter(config.standardLockQuantity.toString());
      viewModel._optionalStandardLockers = config.standardLockerQuantity === 0;
      viewModel._standardLocksQuantityFromLockers = null;
      viewModel._adaLocksQuantity = StringHelper.positiveIntegerFormatter(config.adaLockQuantity.toString());
      viewModel._adaLocksQuantityFromAdaLockers = null;
      viewModel._extraShelvesQuantity = StringHelper.positiveIntegerFormatter(config.adaShelvesQuantity.toString());
      viewModel._extraShelvesQuantityFromAda = null;
      viewModel._bankPreferenceId = config.banksPreference;
      viewModel._bankQuantities = {
        1: config.bankQuantities[1] ?? 0,
        2: config.bankQuantities[2] ?? 0,
        3: config.bankQuantities[3] ?? 0,
        4: config.bankQuantities[4] ?? 0,
        5: config.bankQuantities[5] ?? 0,
        6: config.bankQuantities[6] ?? 0,
      };
      viewModel._numberExtraSides = StringHelper.positiveIntegerFormatter(config.extraSidesQuantity.toString());
      viewModel._numberExtraSidesNumeric = config.extraSidesQuantity;
      const { roomAssistant } = config;
      viewModel._rdaValues = {
        betweenWallsQuantity: roomAssistant.betweenWallConditions,
        backToBackQuantity: roomAssistant.backToBack,
        cornersQuantity: roomAssistant.corners,
        hasBases: roomAssistant.hasBases,
        hasSlopeTops: roomAssistant.hasSlopeTops,
        leftExposedQuantity: roomAssistant.exposedLeftSides,
        rightExposedQuantity: roomAssistant.exposedRightSides,
        totalExposedQuantity: roomAssistant.exposedSides,
        rdaIsEnabled: roomAssistant.rdaIsEnabled,
        slopeTopsAreDirty: roomAssistant.slopeTopsAreDirty,
        basesAreDirty: roomAssistant.basesAreDirty,
      };
      viewModel._enabledRdaValues = {
        betweenWallsQuantity: roomAssistant.betweenWallConditions,
        backToBackQuantity: roomAssistant.backToBack,
        cornersQuantity: roomAssistant.corners,
        hasBases: roomAssistant.hasBases,
        hasSlopeTops: roomAssistant.hasSlopeTops,
        leftExposedQuantity: roomAssistant.exposedLeftSides,
        rightExposedQuantity: roomAssistant.exposedRightSides,
        totalExposedQuantity: roomAssistant.exposedSides,
        rdaIsEnabled: roomAssistant.rdaIsEnabled,
        slopeTopsAreDirty: roomAssistant.slopeTopsAreDirty,
        basesAreDirty: roomAssistant.basesAreDirty,
      };
      viewModel._topsValues = LockerRoomTopsViewModel.fromData(config);
      viewModel._baseValues = {
        ...config.bases,
        zBase: ZBaseViewModel.fromData(config),
        zBasesAreDirty: !config.bases.basesFollowRda && config.bases.baseType === LockerBaseType.ZBase,
        boxBasesAreDirty: !config.bases.basesFollowRda && config.bases.baseType === LockerBaseType.BoxBase,
      };
      viewModel._extraParts = config.extraParts ? config.extraParts : [];
      viewModel._trimAndFillers = LockerTrimAndFillersViewModel.fromData(config);
    }

    return viewModel;
  }

  private _name: string | null;
  private _style: Style | null;
  private _roomType: LockerRoomType | null;
  private _standardLockerQuantity: string | null;
  private _standardLockerNumericalQuantity: number | null;
  private _bootRacksQuantity: string | null;
  private _bootRacksNumericalQuantity: number | null;
  private _bootRacksStartersQuantity: string | null;
  private _bootRacksStartersNumericalQuantity: number | null;
  private _bootRacksAddersQuantity: string | null;
  private _bootRacksAddersNumericalQuantity: number | null;
  private _optionalStandardLockers: boolean;
  private _adaLockerQuantity: string | null;
  private _adaLockerNumericalQuantity: number | null;
  private _extraShelvesQuantity: string | null;
  private _extraShelvesQuantityFromAda: string | null;
  private _standardLocksQuantity: string | null;
  private _standardLocksQuantityFromLockers: string | null;
  private _adaLocksQuantity: string | null;
  private _adaLocksQuantityFromAdaLockers: string | null;
  private _bankPreferenceId: number | null;
  private _bankQuantities: LockerBankQuantities;
  private _numberExtraSides: string;
  private _numberExtraSidesNumeric: number;
  private _rdaValues: LockerRoomAssistantModel;
  private _enabledRdaValues: LockerRoomAssistantModel;
  private _trimAndFillers: LockerTrimAndFillersViewModel;
  private _extraParts: LockerRoomExtraParts[];
  private _topsValues: LockerRoomTopsViewModel;
  private _baseValues: LockerRoomBasesViewModel;

  private creepage = 0.03125;
  private flatTopOverhang = 3;

  constructor() {
    this.name = i18n.global.t('rooms.lockerRoomCreation.defaultLockerRoomName');
    this.style = null;
    this.roomType = null;
    this.standardLockerQuantity = null;
    this.bootRacksQuantity = null;
    this.bootRacksStartersQuantity = null;
    this.bootRacksAddersQuantity = null;
    this.adaLockerQuantity = null;
    this.adaLocksQuantity = null;
    this.extraShelvesQuantity = null;
    this.extraShelvesQuantityFromAda = null;
    this.standardLocksQuantity = null;
    this.optionalStandardLockers = false;
    this.standardLocksQuantityFromLockers = null;
    this.adaLocksQuantityFromAdaLockers = null;
    this.adaLockerNumericalQuantity = null;
    this.standardLockerNumericalQuantity = null;
    this.bankPreferenceId = null;
    this.bankQuantities = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 };
    this.numberExtraSides = '0';
    this.numberExtraSidesNumeric = 0;
    this.rdaValues = this.defaultRdaValues();
    this.enabledRdaValues = this.defaultRdaValues();
    this.topsValues = new LockerRoomTopsViewModel();
    this.trimAndFillers = new LockerTrimAndFillersViewModel();
    this.baseValues = this.defaultBaseValues();
    this.extraParts = [];
  }

  public get name(): string | null {
    return this._name;
  }

  public set name(value: string | null) {
    this._name = value;
  }

  public get style(): Style | null {
    return this._style;
  }

  public set style(value: Style | null) {
    this._style = value;
  }

  public get roomType(): LockerRoomType | null {
    return this._roomType;
  }

  public set roomType(value: LockerRoomType | null) {
    this._roomType = value;
  }

  public get standardLockerQuantity(): string | null {
    return this._standardLockerQuantity;
  }

  public set standardLockerQuantity(value: string | null) {
    this._standardLockerQuantity = value;
  }

  public get bootRacksQuantity(): string | null {
    return this._bootRacksQuantity;
  }

  public set bootRacksQuantity(value: string | null) {
    this._bootRacksQuantity = value;
  }

  public get bootRacksStartersQuantity(): string | null {
    return this._bootRacksStartersQuantity;
  }

  public set bootRacksStartersQuantity(value: string | null) {
    this._bootRacksStartersQuantity = value;
  }

  public get bootRacksAddersQuantity(): string | null {
    return this._bootRacksAddersQuantity;
  }

  public set bootRacksAddersQuantity(value: string | null) {
    this._bootRacksAddersQuantity = value;
  }

  public get bootRacksAddersNumericalQuantity(): number | null {
    return this._bootRacksAddersNumericalQuantity;
  }

  public set bootRacksAddersNumericalQuantity(value: number | null) {
    this._bootRacksAddersNumericalQuantity = value;
  }

  public get bootRacksStartersNumericalQuantity(): number | null {
    return this._bootRacksStartersNumericalQuantity;
  }

  public set bootRacksStartersNumericalQuantity(value: number | null) {
    this._bootRacksStartersNumericalQuantity = value;
  }

  public get optionalStandardLockers(): boolean {
    return this._optionalStandardLockers;
  }

  public set optionalStandardLockers(value: boolean) {
    this._optionalStandardLockers = value;
  }

  public get standardLockerNumericalQuantity(): number | null {
    return this._standardLockerNumericalQuantity;
  }

  public set standardLockerNumericalQuantity(value: number | null) {
    this._standardLockerNumericalQuantity = value;
  }

  public get bootRacksNumericalQuantity(): number | null {
    return this._bootRacksNumericalQuantity;
  }

  public set bootRacksNumericalQuantity(value: number | null) {
    this._bootRacksNumericalQuantity = value;
  }

  public get adaLockerQuantity(): string | null {
    return this._adaLockerQuantity;
  }

  public set adaLockerQuantity(value: string | null) {
    this._adaLockerQuantity = value;
  }

  public get adaLockerNumericalQuantity(): number | null {
    return this._adaLockerNumericalQuantity;
  }

  public set adaLockerNumericalQuantity(value: number | null) {
    this._adaLockerNumericalQuantity = value;
  }

  public get extraShelvesQuantity(): string | null {
    return this._extraShelvesQuantity;
  }

  public set extraShelvesQuantity(value: string | null) {
    this._extraShelvesQuantity = value;
  }

  public get extraShelvesQuantityFromAda(): string | null {
    return this._extraShelvesQuantityFromAda;
  }

  public set extraShelvesQuantityFromAda(value: string | null) {
    this._extraShelvesQuantityFromAda = value;
  }

  public get standardLocksQuantity(): string | null {
    return this._standardLocksQuantity;
  }

  public set standardLocksQuantity(value: string | null) {
    this._standardLocksQuantity = value;
  }

  public get standardLocksQuantityFromLockers(): string | null {
    return this._standardLocksQuantityFromLockers;
  }

  public set standardLocksQuantityFromLockers(value: string | null) {
    this._standardLocksQuantityFromLockers = value;
  }

  public get adaLocksQuantity(): string | null {
    return this._adaLocksQuantity;
  }

  public set adaLocksQuantity(value: string | null) {
    this._adaLocksQuantity = value;
  }

  public get adaLocksQuantityFromAdaLockers(): string | null {
    return this._adaLocksQuantityFromAdaLockers;
  }

  public set adaLocksQuantityFromAdaLockers(value: string | null) {
    this._adaLocksQuantityFromAdaLockers = value;
  }

  public get bankPreferenceId(): number | null {
    return this._bankPreferenceId;
  }

  public set bankPreferenceId(value: number | null) {
    this._bankPreferenceId = value;
  }

  public get bankQuantities(): LockerBankQuantities {
    return this._bankQuantities;
  }

  public set bankQuantities(value: LockerBankQuantities) {
    this._bankQuantities = value;
  }

  public get numberExtraSides(): string {
    return this._numberExtraSides;
  }

  public set numberExtraSides(value: string) {
    this._numberExtraSides = value;
  }

  public get numberExtraSidesNumeric(): number {
    return this._numberExtraSidesNumeric;
  }

  public set numberExtraSidesNumeric(value: number) {
    this._numberExtraSidesNumeric = value;
  }

  public get rdaValues(): LockerRoomAssistantModel {
    return this._rdaValues;
  }

  public set rdaValues(value: LockerRoomAssistantModel) {
    this._rdaValues = value;
  }

  public get enabledRdaValues(): LockerRoomAssistantModel {
    return this._enabledRdaValues;
  }

  public set enabledRdaValues(value: LockerRoomAssistantModel) {
    this._enabledRdaValues = value;
  }

  public get trimAndFillers(): LockerTrimAndFillersViewModel {
    return this._trimAndFillers;
  }

  public set trimAndFillers(value: LockerTrimAndFillersViewModel) {
    this._trimAndFillers = value;
  }

  public get extraParts(): LockerRoomExtraParts[] {
    return this._extraParts;
  }

  public set extraParts(value: LockerRoomExtraParts[]) {
    this._extraParts = value;
  }

  public get topsValues(): LockerRoomTopsViewModel {
    return this._topsValues;
  }

  public set topsValues(value: LockerRoomTopsViewModel) {
    this._topsValues = value;
  }

  public get baseValues(): LockerRoomBasesViewModel {
    return this._baseValues;
  }

  public set baseValues(value: LockerRoomBasesViewModel) {
    this._baseValues = value;
  }

  public setEnabledRdaValues() {
    this.enabledRdaValues = { ...this.rdaValues };

    this.trimAndFillers.starterTrimsAreDirty = false;
    this.trimAndFillers.expansionTrimsAreDirty = false;
    this.trimAndFillers.dressEndsAreDirty = false;
    this.topsValues.slopeTopsAreDirty = false;
    this.topsValues.flatTopsAreDirty = false;
    this.baseValues.boxBasesAreDirty = false;
    this.baseValues.zBasesAreDirty = false;
  }

  public resetToEnabledRdaValues() {
    this.rdaValues = { ...this.enabledRdaValues };
  }

  public creationRequestBasesValues(): LockerRoomBases {
    const { baseType, zBase, boxBase } = this.baseValues;

    const baseValues = {
      ...this.defaultBaseValues(),
      zBase: new ZBaseViewModel().toRequest(),
    };

    if (baseType === LockerBaseType.BoxBase) {
      const basesFollowRda = !this.baseValues.boxBasesAreDirty;

      if (!LockersHelper.isBoxBase(this)) {
        return { ...baseValues, basesFollowRda };
      }

      return { ...baseValues, baseType, boxBase, basesFollowRda };
    }

    if (baseType === LockerBaseType.ZBase) {
      const basesFollowRda = !this.baseValues.zBasesAreDirty;

      if (!LockersHelper.isZBase(this)) {
        return { ...baseValues, basesFollowRda };
      }

      return { ...baseValues, baseType, zBase: zBase.toRequest(), basesFollowRda };
    }

    return { ...baseValues, basesFollowRda: false };
  }

  public totalNumberOfLockers(): number {
    return (this.standardLockerNumericalQuantity ?? 0) + (this.adaLockerNumericalQuantity ?? 0);
  }

  public adaLockerQuantityIsGreaterThanZero(): boolean {
    return !!this.adaLockerNumericalQuantity && this.adaLockerNumericalQuantity > 0;
  }

  public tierIsAdaCompliant(): boolean {
    return !!this.style && LockersHelper.tierIsAdaCompliant(this.style.tier);
  }

  public resetRDAToDefaults() {
    this.rdaValues = this.defaultRdaValues();
    this.enabledRdaValues = this.defaultRdaValues();
  }

  public partialUpdate(update: Partial<LockerRoomViewModel>) {
    Object.assign(this, update);
  }

  get canSkipTrimAndFillers(): boolean {
    const taf = this.trimAndFillers;
    return sum([this.gapWidth, taf.dressEnds, taf.recessTopTrim60, taf.recessSplicePlate, taf.recessSideTrim]) === 0;
  }

  get gapWidth(): number {
    const starterTrimWidth = sumBy(this.trimAndFillers.starterTrims, trim => trim.quantity * trim.width);
    const expansionTrimWidth = sumBy(this.trimAndFillers.expansionTrims, trim => trim.quantity * trim.width);
    const uFillerWidth = sumBy(this.trimAndFillers.uFillerPanels, panel => panel.quantity * panel.width);

    return starterTrimWidth + expansionTrimWidth + uFillerWidth;
  }

  get computedAdaLocksQuantity(): number {
    return this.parseQuantityForRequest(this._adaLocksQuantity, this._adaLocksQuantityFromAdaLockers);
  }

  get computedStandardLocksQuantity(): number {
    return this.parseQuantityForRequest(this._standardLocksQuantity, this._standardLocksQuantityFromLockers);
  }

  get computedAdaShelves(): number {
    return this.parseQuantityForRequest(this._extraShelvesQuantity, this._extraShelvesQuantityFromAda);
  }

  public resetStarterTrims(width: number): void {
    const { betweenWallsQuantity } = this.rdaValues;
    this.trimAndFillers.starterTrims = betweenWallsQuantity > 0 ? [{ quantity: betweenWallsQuantity * 2, width }] : [];
    this.trimAndFillers.starterTrimsAreDirty = false;
  }

  public resetExpansionTrims(width: number): void {
    const { betweenWallsQuantity } = this.rdaValues;
    this.trimAndFillers.expansionTrims =
      betweenWallsQuantity > 0 ? [{ quantity: betweenWallsQuantity * 2, width }] : [];
    this.trimAndFillers.expansionTrimsAreDirty = false;
  }

  public resetDressEnds(): void {
    this.trimAndFillers.dressEnds = this.rdaValues.totalExposedQuantity;
    this.trimAndFillers.showDressEnds = this.trimAndFillers.dressEnds > 0;
    this.trimAndFillers.dressEndsAreDirty = false;
  }

  get trimAndFillerCreationRequest(): LockerTrimAndFillers {
    const taf = this.trimAndFillers;
    const trimsToObj = (trims: LockerTrimModel[]): Record<number, number> =>
      Object.fromEntries(trims.filter(trim => trim.quantity > 0).map(trim => [trim.width, trim.quantity]));

    return {
      starterTrims: trimsToObj(taf.starterTrims),
      starterTrimsFollowRda: !taf.starterTrimsAreDirty,
      expansionTrims: trimsToObj(taf.expansionTrims),
      expansionTrimsFollowRda: !taf.expansionTrimsAreDirty,
      uFillerPanels: trimsToObj(taf.uFillerPanels),
      dressEnds: taf.dressEnds,
      dressEndsFollowRda: !taf.dressEndsAreDirty,
      recessSideTrimPairs: taf.recessSideTrim,
      recessSplicePlates: taf.recessSplicePlate,
      recessTopTrim60: taf.recessTopTrim60,
    };
  }

  public creationRequestTopsValues(): LockerRoomTops {
    const defaultTopsValues = new LockerRoomTopsViewModel().toRequest();
    let request = this.topsValues.toRequest();

    if (this.topsValues.topType === LockerTopType.Flat) {
      request = {
        ...defaultTopsValues,
        flatTopFillers: request.flatTopFillers,
        topType: request.topType,
        topsFollowRda: request.topsFollowRda,
        heavyDutySlopeTops: false,
      };

      if (!LockersHelper.isFlatTops(this)) {
        return { ...request, topType: LockerTopType.Unknown };
      }

      return request;
    }

    if (this.topsValues.topType === LockerTopType.Slope) {
      request = { ...this.topsValues.toRequest(), flatTopFillers: 0 };

      if (!LockersHelper.isSlopeTops(this)) {
        return { ...request, topType: LockerTopType.Unknown };
      }

      return request;
    }

    return { ...defaultTopsValues, topsFollowRda: false };
  }

  public resetTopsToDefaults() {
    this.topsValues = new LockerRoomTopsViewModel();
  }

  public resetBasesToDefaults() {
    this.baseValues = this.defaultBaseValues();
  }

  public calculateBackToBackLengthInInches(): number {
    if (!this.style) {
      return 0;
    }

    const { backToBackQuantity } = this.rdaValues;

    const {
      creepage,
      style: { width },
    } = this;

    const lockersWidth = (backToBackQuantity / 2) * (width + creepage);
    const result = lockersWidth;

    return Math.ceil(result);
  }

  public calculateTotalLengthInInches(): number {
    if (!this.style) {
      return 0;
    }

    const {
      creepage,
      style: { width },
      standardLockerNumericalQuantity,
      adaLockerNumericalQuantity,
    } = this;

    let result = 0;
    if (!width || standardLockerNumericalQuantity == null) {
      return 0;
    }
    const totalNumberOfLockers = standardLockerNumericalQuantity + (adaLockerNumericalQuantity || 0);
    const lockersWidth = totalNumberOfLockers * (width + creepage);
    result += lockersWidth;
    result += this.gapWidth;

    return Math.ceil(result);
  }

  public calculateSlopeTopCoveredLength(): number {
    const lengthFrom72 = this.topsValues.slopeTop72 * 72;
    const lengthFrom48 = this.topsValues.slopeTop48 * 48;
    const lengthFrom36 = this.topsValues.slopeTop36 * 36;
    return lengthFrom72 + lengthFrom48 + lengthFrom36;
  }

  public calculateBaseCoveredLength(): number {
    if (!this.style) {
      return 0;
    }

    const {
      style: { width },
    } = this;

    if (this.baseValues.baseType === LockerBaseType.BoxBase) {
      const lengthFromFrontBacks = Math.ceil((this.baseValues.boxBase.frontBacks / 2) * (width + this.creepage));

      return lengthFromFrontBacks;
    } else if (this.baseValues.baseType === LockerBaseType.ZBase) {
      return this.baseValues.zBase.base60 * 60;
    }

    return 0;
  }

  public prefillFlatTopsFromRDA(): void {
    const totalOverhang = this.rdaValues.betweenWallsQuantity * this.flatTopOverhang;
    const neededFlatTopLength = this.gapWidth + totalOverhang;

    this.topsValues.flatTopFillers = this.rdaValues.cornersQuantity + Math.ceil(neededFlatTopLength / 20);
    this.topsValues.flatTopsAreDirty = false;
  }

  public prefillSlopeTopsValuesFromRDA(): void {
    const { hasSlopeTops, leftExposedQuantity, rightExposedQuantity, cornersQuantity, slopeTopsAreDirty } =
      this.rdaValues;
    const lockerWidth = this.calculateTotalLengthInInches();

    this.topsValues = new LockerRoomTopsViewModel(this.topsValues);

    const temptative72Count = lockerWidth / 72;
    this.topsValues.slopeTop72 = Math.floor(temptative72Count);
    const remainder = Math.ceil((temptative72Count % 1) * 72);
    if (remainder > 48) {
      this.topsValues.slopeTop72 = Math.floor(temptative72Count) + 1;
    } else if (remainder <= 48 && remainder > 36) {
      this.topsValues.slopeTop48 = 1;
    } else {
      this.topsValues.slopeTop36 = 1;
    }
    const numberOfSlopeTops = this.topsValues.slopeTop72 + this.topsValues.slopeTop48 + this.topsValues.slopeTop36;
    this.topsValues.spliceAssemblies = numberOfSlopeTops - 1;
    this.topsValues.leftHandGussets = leftExposedQuantity;
    this.topsValues.rightHandGussets = rightExposedQuantity;
    this.topsValues.cornerSlopeTop36 = cornersQuantity;

    if (slopeTopsAreDirty) {
      if (!hasSlopeTops) {
        this.resetTopsToDefaults();
      } else {
        this.topsValues.topType = LockerTopType.Slope;
      }
    }

    this.rdaValues.slopeTopsAreDirty = false;
    this.topsValues.slopeTopsAreDirty = false;
  }

  public prefillBoxBaseValuesFromRDA(): void {
    const { hasBases, basesAreDirty } = this.rdaValues;

    this.baseValues.boxBase.frontBacks = this.getDefaultFrontBacksValue();
    this.baseValues.boxBase.boxBaseSides = this.getDefaultBoxBaseSidesValue();

    if (basesAreDirty) {
      if (!hasBases) {
        this.resetBasesToDefaults();
      }
    }

    this.rdaValues.basesAreDirty = false;
    this.baseValues.boxBasesAreDirty = false;
  }

  public getDefaultFrontBacksValue(): number {
    return this.totalNumberOfLockers() * 2;
  }

  public getDefaultBoxBaseSidesValue(): number {
    return this.totalNumberOfLockers() + BanksCalculator.minimumExtraSides(this.bankQuantities);
  }

  public prefillZBaseValuesFromRDA() {
    const { hasBases } = this.rdaValues;

    this.baseValues.zBase.base60 = Math.ceil(this.calculateTotalLengthInInches() / 60);
    this.baseValues.zBase.ends = this.rdaValues.totalExposedQuantity;
    this.baseValues.zBase.expansionTrims = Math.ceil(this.calculateBackToBackLengthInInches() / 72);
    this.baseValues.zBase.splicePlates = Math.max(this.baseValues.zBase.base60 - 1, 0);
    this.baseValues.zBasesAreDirty = false;

    if (!hasBases) {
      this.resetBasesToDefaults();
    }
  }

  private defaultRdaValues = (): LockerRoomAssistantModel => ({
    betweenWallsQuantity: 0,
    cornersQuantity: 0,
    totalExposedQuantity: 0,
    hasSlopeTops: false,
    hasBases: false,
    leftExposedQuantity: 0,
    rightExposedQuantity: 0,
    backToBackQuantity: 0,
    rdaIsEnabled: false,
    slopeTopsAreDirty: false,
    basesAreDirty: false,
  });

  private defaultBaseValues = (): LockerRoomBasesViewModel => ({
    baseType: LockerBaseType.Unknown,
    boxBasesAreDirty: false,
    zBasesAreDirty: false,
    boxBase: {
      frontBacks: 0,
      boxBaseSides: 0,
      backToBack32: 0,
      corner4x10: 0,
      corner4x4: 0,
    },
    zBase: new ZBaseViewModel(),
  });

  private parseQuantityForRequest(mainValue: string | null, fallback: string | null): number {
    if (mainValue != null) {
      return NumberFormatter.strictParseInt(mainValue) || 0;
    }

    if (fallback != null) {
      return NumberFormatter.strictParseInt(fallback) || 0;
    }

    return 0;
  }
}
