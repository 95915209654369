import { defineComponent } from 'vue';

export default defineComponent({
  name: 'hadrianTooltip',
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    tooltipClass: {
      type: String,
    },
    tooltipTextContainerStyle: {
      type: Object,
    },
    tooltipText: {
      type: String,
    },
    inset: {
      type: Boolean,
      default: false,
    },
    verticalPosition: {
      type: String as () => 'above' | 'below' | 'centered',
      default: 'below',
    },
    anchor: {
      type: String as () => 'left' | 'center' | 'right' | 'horizontal-left',
      default: 'right',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    showNoHover: {
      type: Boolean,
      default: false,
    },
  },
});
