import HttpService from '@/endpoints/HttpService';
import HttpResponse from '@/interfaces/HttpResponse';
import IPartsTableParams from '@/interfaces/IPartsTableParams';
import { CUDProjectPartsResponse, LegacyPart, ProjectPartsResponse } from '@/models/part/projectParts';
import ProjectPartsCreation from '@/models/part/projectPartsCreation';
import {
  DeleteProjectPartRequest,
  ProjectBOMParts,
  ProjectCustomLengthPartRemovalRequest,
  RoombBOMParts,
  RoomBomRequest,
} from '@/models/project/projectApiInterfaces';
import qs from 'qs';

export default class ProjectPartsService {
  public static async getParts(params: IPartsTableParams): Promise<ProjectPartsResponse> {
    const res = await HttpService.get(`${process.env.VUE_APP_API_PREFIX}parts/filter`, {
      params,
      paramsSerializer: p => qs.stringify(p, { arrayFormat: 'repeat' }),
    });

    const response = {
      entities: res.data.entity?.partsReply,
      legacyParts: res.data.entity?.legacyParts,
      ...res.data,
    };
    const { entity, ...obj } = response;
    return obj;
  }

  public static async getProjectParts(projectId: string) {
    const res = await HttpService.get(`${process.env.VUE_APP_API_PREFIX}parts/projects/${projectId}`);
    return res.data.entities;
  }

  public static async getOrderParts(orderId: string) {
    const res = await HttpService.get(`${process.env.VUE_APP_API_PREFIX}orders/${orderId}/projectParts`);
    return res.data.entities;
  }

  public static async getBomParts(projectId: string): Promise<HttpResponse<ProjectBOMParts>> {
    const res = await HttpService.postWithLoading(`${process.env.VUE_APP_API_PREFIX}projects/${projectId}/bom`);
    return res;
  }

  public static async getRoomBomParts(request: RoomBomRequest): Promise<HttpResponse<RoombBOMParts>> {
    const res = await HttpService.post(
      `${process.env.VUE_APP_API_PREFIX}projects/${request.projectId}/room/${request.roomId}/bom`
    );
    return res;
  }

  public static async updateRoomBomMissingParts(request: RoomBomRequest): Promise<HttpResponse<RoombBOMParts>> {
    const res = await HttpService.post(
      `${process.env.VUE_APP_API_PREFIX}projects/${request.projectId}/room/${request.roomId}/updateBomMissingParts`
    );
    return res;
  }

  public static async deleteProjectPart(
    request: DeleteProjectPartRequest
  ): Promise<HttpResponse<CUDProjectPartsResponse>> {
    const res = await HttpService.delete(
      `${process.env.VUE_APP_API_PREFIX}parts/${request.partId}/project/{${request.projectId}}`
    );
    return res;
  }

  public static async removeCustomLengthPart(
    request: ProjectCustomLengthPartRemovalRequest
  ): Promise<HttpResponse<CUDProjectPartsResponse>> {
    return await HttpService.delete(
      `${process.env.VUE_APP_API_PREFIX}parts/projects/${request.projectId}/customLengthPart/${request.partId}`
    );
  }

  public static async addProjectPart(projectPart: ProjectPartsCreation): Promise<CUDProjectPartsResponse> {
    const params = {
      ProjectId: projectPart.projectId,
      PartId: projectPart.partId,
      Quantity: projectPart.quantity,
      AffValue: projectPart.affValue,
      CeilingHeight: projectPart.ceilingHeight,
      DoorHeight: projectPart.doorHeight,
      DoorWidth: projectPart.doorWidth,
    };

    const response = await HttpService.post(`${process.env.VUE_APP_API_PREFIX}parts`, params);
    return response.data;
  }

  public static async postProjectSuggestedParts(
    projectParts: ProjectPartsCreation[]
  ): Promise<CUDProjectPartsResponse> {
    const projectPartsList = projectParts.map(projectPart => {
      return {
        ProjectId: projectPart.projectId,
        PartId: projectPart.partId,
        Quantity: projectPart.quantity,
      };
    });

    const response = await HttpService.post(`${process.env.VUE_APP_API_PREFIX}parts/suggestedParts`, projectPartsList);
    return response.data;
  }

  public static async patchProjectPart(
    partId: string,
    quantity: number
  ): Promise<HttpResponse<CUDProjectPartsResponse>> {
    const operation = {
      op: 'replace',
      path: '/quantity',
      value: quantity,
    };

    return await HttpService.patch(`${process.env.VUE_APP_API_PREFIX}parts/${partId}`, [operation]);
  }

  public static async getLegacyParts(part: LegacyPart) {
    return await HttpService.postWithLoading(`${process.env.VUE_APP_API_PREFIX}parts/legacy`, part);
  }
}
