import { CustomFinish } from '@/constants/FinishCodes';
import GenderType from '@/enums/genderType';
import PartitionTypes from '@/enums/partitionTypes';
import PermissionTypes from '@/enums/permissionTypes';
import RoomType from '@/enums/roomType';
import { vxManager } from '@/store/store';
import Finish from '../finish/finish';
import HadrianBase from '../hadrianBase';
import LockerRoomConfiguration from '../lockers/lockerRoomConfiguration';
import { RoomBomPartsPart } from '../project/projectApiInterfaces';
import RdDrawingNote from '../roomDesigner/rdDrawingNote';
import RoomBowl from './roomBowl';
import RoomLayout from './roomLayout';
import RoomObstruction from './roomObstruction';
import RoomSetting from './roomSetting';
import RoomUnit from './roomUnit';
import RoomScreen from './screens/roomScreens';

export default class Room extends HadrianBase {
  readonly roomName: string;
  readonly creationDate: string;
  readonly lastUpdatedDate: string;
  readonly floor: number | null;
  readonly lockerRoomConfigurations: LockerRoomConfiguration[];
  drawingNotes: RdDrawingNote[];
  readonly gender: GenderType;
  readonly roomLayout: RoomLayout;
  readonly roomSettings: RoomSetting;
  readonly roomObstructions: RoomObstruction[];
  readonly roomBowls: RoomBowl[];
  readonly roomUnits: RoomUnit[];
  roomScreens: RoomScreen[];
  netPrice: number;
  readonly orderInformation_IsOrdered: string;
  readonly orderInformation_PONumber: string;
  readonly isSoldOut: boolean;
  readonly containsTbaParts: boolean;
  readonly containsCustomColorParts: boolean;
  readonly containsBomEdits: boolean;
  readonly overallWidth: number;
  readonly displayOrder: number;
  readonly canAddConfigurations: boolean;
  bomIsMissingParts: boolean;
  workingDirection: number;
  roomParts: RoomBomPartsPart[];
  quoteDuplicatedRoomId: string;
  quoteAssociatedRoomId: string;
  orderDuplicatedRoomId: string;
  orderAssociatedRoomId: string;
}

export interface RoomOrder {
  [id: string]: number;
}

export function buildRoomOrderRequest(oldRooms: Room[], newRooms: Room[]): RoomOrder {
  const oldOrder = mapRoomOrder(oldRooms);
  const newOrder = mapRoomOrder(newRooms);

  const request: RoomOrder = {};
  oldRooms.forEach(room => {
    if (oldOrder[room.id] !== newOrder[room.id]) {
      request[room.id] = newOrder[room.id];
    }
  });

  return request;
}

function mapRoomOrder(rooms: Room[]): RoomOrder {
  return rooms.reduce((acc, room, index) => ({ ...acc, [room.id]: index + 1 }), {});
}

export const searchInputMatchesRoom = (room: Room, searchInput: string): boolean => {
  const matchesName = room.roomName.toLowerCase().includes(searchInput.toLowerCase());

  if (!room.lockerRoomConfigurations?.length) {
    return matchesName;
  }

  const matchesStyleName = room.lockerRoomConfigurations.some(configuration =>
    configuration.styleName.toLowerCase().includes(searchInput.toLowerCase())
  );

  return matchesName || matchesStyleName;
};

function hasFinishOfType(room: Room, code: string): boolean {
  function isCode(finish: Finish | null): boolean {
    return finish?.code === code;
  }
  if (room.lockerRoomConfigurations) {
    if (
      room.lockerRoomConfigurations.some(
        x => x.style && (isCode(x.style.bodyFinish) || isCode(x.style.doorFinish) || isCode(x.style.frameFinish))
      )
    ) {
      return true;
    }
  } else if (room.roomSettings) {
    if (isCode(room.roomSettings.finish)) {
      return true;
    }
  }
  return false;
}

export function roomContainsCustomColor(room: Room): boolean {
  if (hasFinishOfType(room, CustomFinish)) {
    return true;
  }
  return room.containsCustomColorParts;
}

export function roomCanBeQuoted(room: Room): boolean {
  return !room.isSoldOut && !room.bomIsMissingParts;
}

export function roomCanBeOrdered(room: Room): boolean {
  return (
    !room.isSoldOut &&
    (!room.bomIsMissingParts || vxManager.userModule.hasPermission(PermissionTypes.CanEditBOMMissingPartsProjects)) &&
    !room.containsTbaParts &&
    !roomIsEmpty(room) &&
    roomHasValidCeilingHeight(room) &&
    !room.orderInformation_IsOrdered
  );
}

export function roomIsEmpty(room: Room): boolean {
  if (room.lockerRoomConfigurations) {
    return (room.roomParts?.length ?? 0) === 0;
  }
  return (room.roomUnits?.length ?? 0) === 0 && (room.roomScreens?.length ?? 0) === 0 && !room.containsBomEdits;
}

export function roomHasValidCeilingHeight(room: Room): boolean {
  if (!room.roomSettings) {
    return true;
  }
  if (
    !!room.roomLayout &&
    (room.roomLayout.rlid === RoomType.screensOnly || room.roomLayout.rlid === RoomType.partsOnly)
  ) {
    return true;
  }
  const roomAffOption = vxManager.projectSettingsModule.getAllPartitionsDefaults
    .find(x => x.id === room.roomSettings.partition.id)
    ?.affOptions.find(y => y.id === room.roomSettings.affOptions.id);
  if (!!roomAffOption?.maxCeiling || !!roomAffOption?.minCeiling) {
    return (
      !!room.roomSettings.ceilingHeight &&
      room.roomSettings.ceilingHeight >= (roomAffOption?.minCeiling ?? 0) &&
      room.roomSettings.ceilingHeight <= (roomAffOption?.maxCeiling ?? Number.MAX_SAFE_INTEGER)
    );
  }
  // Fallback if vxManager isn't ready
  const partitionNeedsCeilingHeight = [PartitionTypes.Fc, PartitionTypes.Ch].includes(room.roomSettings.partition.type);
  if (partitionNeedsCeilingHeight) {
    return !!room.roomSettings.ceilingHeight;
  }

  return true;
}
